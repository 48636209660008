require('./bootstrap');
window.Swal = require('sweetalert2');
window.Toastify = require('toastify-js');
$(document).on('click', ".copy-action", copyContentOnClipBoard);
function copyContentOnClipBoard(){
    var content = $(this).attr('data-clipboard-text');
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(content).select();
    document.execCommand("copy");
    $temp.remove();
    swallMessage(
        'Copiado al portapapeles'
        , content
        , 'success'
        , null
        , null
        , 3000
        , null
        , null
    );
}